<template lang="pug">
include ../../../configs/template
div.row.text-left.pt-8
  div.col-6
    div.col-12
      +data-info('sailorFullName', 'sailorFullName')
    div.col-12
      +data-info('createDate', 'statement.created_at')
    div.col-12
      +data-info('number', 'statement.number')
    div.col-12
      +data-info('statementPayment', 'statement.is_payed_general ? $t("isPayed") : $t("notPayed")')
    div.col-12
      +data-info-status('status', 'getDirectoryObject({value: "statuses", id: statement.status_document})[nameLang]', 'getStatus(statement.status_document)')
  div.col-6(v-if="statement.nostrification_document_status")
    div.col-12
      div
        +data-info('payment_translation', 'statement.is_payed_translate ? $t("isPayed") : $t("notPayed")')
      div
        +data-info('payment_nostrification', 'statement.is_payed_nostrification ? $t("isPayed") : $t("notPayed")')
    div.col-12
      +data-info-status('status_nostrification_document', 'getDirectoryObject({value: "statuses", id: statement.nostrification_document_status})[nameLang]', 'getStatus(statement.nostrification_document_status)')
  div.col-12(v-if="statement.comments?.length")
    h4.fs-18.mb-3 {{$t('comment')}}:
    div(v-for="comment in statement.comments" :key="comment.id").ml-8
      div(v-if="checkAccess('backOffice')")
        +data-info('createdBy', 'comment.created_by.name')
      div
        +data-info('createDate', 'new Date(comment.created_by.date).toLocaleString()')
      div
        +data-info('setComment', 'comment.comment')
  div.col-12(v-if="!includesStatus.includes(statement.status_document)")
    DocumentEducation(
      v-if="statement.nostrification_document && statement.nostrification_document_status !== 122"
      :documentId="statement.nostrification_document"
      :files="statement.files.filter(el => el.file_type === 'origin_document')")
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { getStatus } from '@/mixins/main'
import { STATUSES_DOCUMENT_CONSTANTS } from '@/configs/constants'
import { checkAccess } from '@/mixins/permissions'
export default {
  components: {
    DocumentEducation: () => import('@/views/Documents/DocumentNostrification/Document.vue')
  },
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      getStatus,
      includesStatus: [
        STATUSES_DOCUMENT_CONSTANTS.rejectByTransator,
        STATUSES_DOCUMENT_CONSTANTS.rejectedByCrewing,
        STATUSES_DOCUMENT_CONSTANTS.rejectByNostrification
      ]
    }
  },
  computed: {
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    }),
    ...mapGetters(['getDirectoryObject']),
    sailorFullName () {
      return this.statement.sailor[`full_name_ukr`]
    }
  }
}
</script>
